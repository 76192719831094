<template>
  <div class="section">
    <div class="container">
      <div class="section-title-wide no-bottom-space">
        <h6 class="subheading text-primary-1">{{ content.preview_text }}</h6>

        <h1>{{ content.heading }}</h1>
        <h4>{{ content.sub_header }}</h4>

        <!-- <stripe-pricing-table
          pricing-table-id="prctbl_1LPb2fBfPqBF9CKCiPJ3YdME"
          publishable-key="pk_test_0l8vOgcSExT86FUSawySl4JC00gMwkAROF"
          customer-email= "{email:"
          client-refrence-id="tenant#33"
        >
        </stripe-pricing-table> -->

      <stripe-pricing-table
          v-bind="[{'customer-email' : customerEmail},{'publishable-key': publishableKey},{'pricing-table-id': pricingTableKey},{'client-refrence-id': tenantId}]"
        >
        </stripe-pricing-table>
        
      </div>
    </div>
  </div>
</template>

<script>
import PageService from "@/services/pageService.js";
import { teamService } from "@/services";
export default {
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_KEY;
    this.pricingTableKey = process.env.VUE_APP_STRIPE_PRICING_TABLE_ID;
    return {
      loading: false,
      customerEmail: "",
      tenantId: 0,
      content: [],
    };
  },
  methods: {},
  mounted() {
    //     teamService.getCurrent().then((data) => {
    //   console.log(data);
    //   this.team = data;
    //   this.tenantId = data.id;
    //   this.customerEmail = data.adminEmail;
    // });

  },
  created() {
    PageService.getPage("plan-picker-page")
      .then((response) => {
        this.content = response.data.data.fields;
        if (this.$route.query.email) {
          this.customerEmail = this.$route.query.email;
          this.tenantId = "tenant#"+this.$route.query.tenant;
        }
      })
      .catch((error) => {
        console.log("there was an error getting the content", error);
      });
  },
};

</script>